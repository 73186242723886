import { PeriodUnit, Recurrence } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { TFunction } from '@wix/yoshi-flow-editor';

export const getPeriodLabel = (recurrence: Recurrence | undefined, t: TFunction) => {
  const unit = recurrence?.cycleDuration?.unit;
  const count = recurrence?.cycleDuration?.count;

  if (!unit || !count) {
    return null;
  }

  switch (unit) {
    case PeriodUnit.DAY:
      return t('pp.period-recurrency.every-day-plural', { count });
    case PeriodUnit.WEEK:
      return t('pp.period-recurrency.every-week');
    case PeriodUnit.YEAR:
      return t('pp.period-recurrency.every-year');
    case PeriodUnit.MONTH:
      return t('pp.period-recurrency.every-month');
    default:
      return '';
  }
};

export const getPeriodLabelV2 = (recurrence: Recurrence | undefined, t: TFunction) => {
  const unit = recurrence?.cycleDuration?.unit;
  const count = recurrence?.cycleDuration?.count;

  if (!unit || !count) {
    return null;
  }

  switch (unit) {
    case PeriodUnit.DAY:
      return t('pp.period-recurrency.every-day-plural', { count });
    case PeriodUnit.WEEK:
      return t('pp.period-recurrency.every-week-plural', { count });
    case PeriodUnit.YEAR:
      return t('pp.period-recurrency.every-year-plural', { count });
    case PeriodUnit.MONTH:
      return t('pp.period-recurrency.every-month-plural', { count });
    default:
      return '';
  }
};
